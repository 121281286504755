// src/App.js

import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import UserRegistrationScreen from './pages/UserRegistrationScreen';
import EmailVerificationScreen from './pages/EmailVerificationScreen';
import UserLoginScreen from './pages/UserLoginScreen';
import PasswordResetRequest from './pages/PasswordResetRequest';
import MyAccountScreen from './pages/MyAccountScreen';
import AdminHomeScreen from './pages/AdminHomeScreen';
import ChangePasswordScreen from './pages/ChangePasswordScreen';
import UserLogoutScreen from './pages/UserLogoutScreen';
import DeleteAccountScreen from './pages/DeleteAccountScreen';
import CreateProjectScreen from './pages/CreateProjectScreen';
import ProjectListScreen from './pages/ProjectListScreen';
import ProjectHomeScreen from './pages/ProjectHomeScreen';
import NewProjectContextScreen from './pages/NewProjectContextScreen';
import ProjectContextHomeScreen from './pages/ProjectContextHomeScreen';
import ContextInformationScreen from './pages/ContextInformationScreen';
import ProjectInfoUploadScreen from './pages/ProjectInfoUploadScreen';
import ViewAttachmentScreen from './pages/ViewAttachmentScreen';
import ShareProjectScreen from './pages/ShareProjectScreen';
import NewStoryScreen from './pages/NewStoryScreen';
import StoryHomeScreen from './pages/StoryHomeScreen';
import StoryLoglineScreen from './pages/StoryLoglineScreen';
import StoryScriptScreen from './pages/StoryScriptScreen';
import NewCharacterScreen from './pages/NewCharacterScreen';
import CharacterHomeScreen from './pages/CharacterHomeScreen';
import CharacterVoiceScreen from './pages/CharacterVoiceScreen';
import CharacterImageScreen from './pages/CharacterImageScreen';
import DefaultVoicesScreen from './pages/DefaultVoicesScreen';
import ScriptAudioScreen from './pages/ScriptAudioScreen';
import NavBar from './components/NavBar';
import { UserProvider } from './UserContext';
import { styles } from './styles';

function App() {
  return (
    <UserProvider>
      <div style={styles.app}>
        <NavBar />
        <main style={styles.mainContent}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/register" element={<UserRegistrationScreen />} />
            <Route path="/emailverification" element={<EmailVerificationScreen />} />
            <Route path="/login" element={<UserLoginScreen />} />
            <Route path="/passwordresetrequest" element={<PasswordResetRequest />} />
            <Route path="/myaccount" element={<MyAccountScreen />} />
            <Route path="/admin" element={<AdminHomeScreen />} />
            <Route path="/changepassword" element={<ChangePasswordScreen />} />
            <Route path="/logout" element={<UserLogoutScreen />} />
            <Route path="/deleteaccount" element={<DeleteAccountScreen />} />
            <Route path="/createproject" element={<CreateProjectScreen />} />
            <Route path="/projectlist" element={<ProjectListScreen />} />
            <Route path="/project/:projectId" element={<ProjectHomeScreen />} />
            <Route path="/new-project-context/:projectId" element={<NewProjectContextScreen />} />
            <Route path="/project-context/:contextId" element={<ProjectContextHomeScreen />} />
            <Route path="/context-information/:contextId" element={<ContextInformationScreen />} />
            <Route path="/context-information/:contextId/upload" element={<ProjectInfoUploadScreen />} />
            <Route path="/view-attachment/:contextId" element={<ViewAttachmentScreen />} />
            <Route path="/share/:projectId" element={<ShareProjectScreen />} />
            <Route path="/new-story/:projectId" element={<NewStoryScreen />} />
            <Route path="/story/:storyId" element={<StoryHomeScreen />} />
            <Route path="/story/:storyId/edit-logline" element={<StoryLoglineScreen />} />
            <Route path="/story/:storyId/edit-script" element={<StoryScriptScreen />} />
            <Route path="/new-character/:projectId" element={<NewCharacterScreen />} />
            <Route path="/character/:characterId" element={<CharacterHomeScreen />} />
            <Route path="/character-voice/:characterId" element={<CharacterVoiceScreen />} />
            <Route path="/character-image/:characterId" element={<CharacterImageScreen />} />
            <Route path="/default-voices" element={<DefaultVoicesScreen />} />
            <Route path="/story/:storyId/script-audio" element={<ScriptAudioScreen />} />
          </Routes>
        </main>
      </div>
    </UserProvider>
  );
}

export default App;