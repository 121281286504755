// src/components/CharacterImageDetails.js

import React, { useState } from 'react';
import { Pencil } from 'lucide-react';
import { styles } from '../styles';
import { API_BASE_URL } from '../config';

const CharacterImageDetails = ({ details, characterId, userId }) => {
  // Store editing states for each field
  const [editingStates, setEditingStates] = useState({});
  const [editingValues, setEditingValues] = useState({});
  const [loadingStates, setLoadingStates] = useState({});

  if (!details) return null;

  const detailGroups = [
    {
      title: 'Basic Information',
      fields: ['species', 'sex', 'age', 'height', 'body_type']
    },
    {
      title: 'Physical Features',
      fields: ['head_features', 'eye_features', 'facial_features', 'distinctive_markings', 'surface_appearance']
    },
    {
      title: 'Clothing & Accessories',
      fields: ['upper_body_clothing', 'lower_body_clothing', 'footwear', 'accessories']
    },
    {
      title: 'Style',
      fields: ['art_style']
    }
  ];

  const formatFieldName = (name) => {
    return name
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  const handleEditStart = (field) => {
    setEditingStates(prev => ({ ...prev, [field]: true }));
    setEditingValues(prev => ({ ...prev, [field]: details[field] || '' }));
  };

  const handleEditCancel = (field) => {
    setEditingStates(prev => ({ ...prev, [field]: false }));
    setEditingValues(prev => ({ ...prev, [field]: details[field] || '' }));
  };

  const handleValueChange = (field, value) => {
    setEditingValues(prev => ({ ...prev, [field]: value }));
  };

  const handleFieldSave = async (field) => {
    setLoadingStates(prev => ({ ...prev, [field]: true }));
    try {
      const response = await fetch(`${API_BASE_URL}/character-image/${characterId}/update-field`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user_id: userId,
          field_name: field,
          field_value: editingValues[field]
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to update field');
      }

      // Update was successful, update the UI state
      details[field] = editingValues[field];
      setEditingStates(prev => ({ ...prev, [field]: false }));
    } catch (error) {
      console.error('Error updating field:', error);
      // You might want to show an error message to the user here
    } finally {
      setLoadingStates(prev => ({ ...prev, [field]: false }));
    }
  };

  // Determine if a field should use multiline editing
  const isMultilineField = (field) => {
    const multilineFields = [
      'head_features', 'facial_features', 'distinctive_markings',
      'surface_appearance', 'upper_body_clothing', 'lower_body_clothing',
      'accessories'
    ];
    return multilineFields.includes(field);
  };

  const renderField = (field) => {
    const isEditing = editingStates[field];
    const isLoading = loadingStates[field];
    const editValue = editingValues[field];
    const isMultiline = isMultilineField(field);

    return (
      <div key={field} style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
        <div style={{
          display: 'flex',
          alignItems: 'center',
          gap: '8px'
        }}>
          <button
            onClick={() => isEditing ? handleEditCancel(field) : handleEditStart(field)}
            style={{
              ...styles.button,
              ...styles.editButton,
              padding: '4px',
              minWidth: '24px',
              height: '24px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <Pencil size={14} />
          </button>
          <dt style={{
            fontSize: '0.875rem',
            fontWeight: '500',
            color: 'rgba(255, 255, 255, 0.7)'
          }}>
            {formatFieldName(field)}
          </dt>
        </div>
        <dd style={{
          color: '#fff',
          fontSize: '1rem',
          margin: 0
        }}>
          {isEditing ? (
            <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
              {isMultiline ? (
                <textarea
                  value={editValue}
                  onChange={(e) => handleValueChange(field, e.target.value)}
                  style={{
                    ...styles.textarea,
                    minHeight: '100px'
                  }}
                />
              ) : (
                <input
                  type="text"
                  value={editValue}
                  onChange={(e) => handleValueChange(field, e.target.value)}
                  style={styles.formInput}
                />
              )}
              <div style={{
                display: 'flex',
                gap: '8px'
              }}>
                <button 
                  onClick={() => handleFieldSave(field)}
                  disabled={isLoading}
                  style={{
                    ...styles.button,
                    padding: '4px 12px',
                    fontSize: '0.875rem'
                  }}
                >
                  {isLoading ? 'Saving...' : 'Save'}
                </button>
                <button
                  onClick={() => handleEditCancel(field)}
                  style={{
                    ...styles.button,
                    padding: '4px 12px',
                    fontSize: '0.875rem',
                    backgroundColor: '#6c757d'
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          ) : (
            <span>{details[field] || 'Not specified'}</span>
          )}
        </dd>
      </div>
    );
  };

  return (
    <div style={{
      marginTop: '30px',
      width: '100%'
    }}>
      {detailGroups.map((group, groupIndex) => (
        <div
          key={groupIndex}
          style={{
            backgroundColor: 'rgba(255, 255, 255, 0.05)',
            borderRadius: '8px',
            padding: '24px',
            marginBottom: '20px'
          }}
        >
          <h3 style={{
            fontSize: '1.25rem',
            fontWeight: '600',
            color: '#fff',
            borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
            paddingBottom: '8px',
            marginBottom: '16px'
          }}>
            {group.title}
          </h3>
          <div style={{
            display: 'grid',
            gap: '16px'
          }}>
            {group.fields.map(renderField)}
          </div>
        </div>
      ))}
    </div>
  );
};

export default CharacterImageDetails;