// src/config.js

// only export one back end API_BASE_URL below at a time; comment out the others

// to test back end (heroku link)
// export const API_BASE_URL = 'https://virtualstudioback-d0223b8c5b80.herokuapp.com/';

// to deploy back end (heroku link)
// export const API_BASE_URL = 'https://virtualstudioback-d0223b8c5b80.herokuapp.com/';

// for https://app.primeintellect.ai/ back end
// export const API_BASE_URL = 'http://104.129.132.157:14904';

// for runpod.io back end
export const API_BASE_URL = 'https://no543ufd5iyiqo-8888.proxy.runpod.net';

// google cloud run back end; note this is not functional yet
// export const API_BASE_URL = 'https://virtualstudioback-297906722855.us-central1.run.app';

// for base url links
export const BASE_URL = 'https://virtual-studio.ai';

// You can add other configuration variables here as needed
export const APP_NAME = 'Virtual Studio';
export const VERSION = '1.0.0';