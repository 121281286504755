// src/pages/CharacterImageScreen.js

import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { useUser } from '../UserContext';
import { styles } from '../styles';
import { API_BASE_URL } from '../config';
import { Pencil, ImageIcon } from 'lucide-react';
import CharacterImageDetails from '../components/CharacterImageDetails';

const CharacterImageScreen = () => {
  const [character, setCharacter] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isEditingDescription, setIsEditingDescription] = useState(false);
  const [imageDescription, setImageDescription] = useState('');
  const [permissionLevel, setPermissionLevel] = useState(null);
  const [imageDetails, setImageDetails] = useState(null);
  const [isGenerating, setIsGenerating] = useState(false);
  const [isGeneratingImage, setIsGeneratingImage] = useState(false);
  const [latestImageUrl, setLatestImageUrl] = useState(null);
  const { user } = useUser();
  const { characterId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (user.isAuthenticated) {
      fetchCharacterDetails();
    } else {
      navigate('/login');
    }
  }, [characterId, user.isAuthenticated, navigate]);

  const fetchCharacterDetails = async () => {
    if (!user.user_id || !characterId) return;

    try {
      const response = await fetch(`${API_BASE_URL}/character/${characterId}/details?user_id=${user.user_id}`);
      
      if (!response.ok) {
        throw new Error('Failed to fetch character details');
      }

      const data = await response.json();
      setCharacter(data.character);
      setPermissionLevel(data.permissionLevel);

      // Fetch image description and details
      const imageResponse = await fetch(`${API_BASE_URL}/character-image/${characterId}/description?user_id=${user.user_id}`);
      
      if (imageResponse.ok) {
        const imageData = await imageResponse.json();
        setImageDescription(imageData.description || '');
        setImageDetails(imageData.image_details);
        // Also get the latest image URL if it exists
        if (imageData.image_details?.latest_image_url) {
          setLatestImageUrl(imageData.image_details.latest_image_url);
        }
      }
    } catch (err) {
      console.error('Error:', err);
      setError(err.message || 'An error occurred');
    } finally {
      setIsLoading(false);
    }
  };

  const handleDescriptionChange = (e) => {
    setImageDescription(e.target.value);
  };

  const handleDescriptionSave = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/character-image/${characterId}/update-description`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          user_id: user.user_id,
          description: imageDescription 
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to update description');
      }

      setIsEditingDescription(false);
    } catch (err) {
      console.error('Error updating description:', err);
      setError('Failed to update description. Please try again.');
    }
  };

  const handleGenerateImageDetails = async () => {
    try {
      setIsGenerating(true);
      setError(null);

      const response = await fetch(`${API_BASE_URL}/character-image/${characterId}/generate-image`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          user_id: user.user_id
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to generate image details');
      }

      const data = await response.json();
      setImageDetails(data.image_details);
    } catch (err) {
      console.error('Error generating image details:', err);
      setError('Failed to generate image details. Please try again.');
    } finally {
      setIsGenerating(false);
    }
  };

  const handleGenerateImage = async () => {
    try {
      setIsGeneratingImage(true);
      setError(null);

      const response = await fetch(`${API_BASE_URL}/character-image/${characterId}/generate-from-details`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          user_id: user.user_id
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to generate image');
      }

      const data = await response.json();
      setLatestImageUrl(data.latest_image_url);
    } catch (err) {
      console.error('Error generating image:', err);
      setError('Failed to generate image. Please try again.');
    } finally {
      setIsGeneratingImage(false);
    }
  };

  if (isLoading) return <div style={styles.container}>Loading...</div>;
  if (error) return <div style={styles.container}>{error}</div>;
  if (!character) return <div style={styles.container}>Character not found.</div>;

  const canEdit = permissionLevel === 'owner' || permissionLevel === 'editor';

  return (
    <div style={styles.container}>
      <div style={styles.linkContainer}>
        <Link to={`/character/${characterId}`} style={styles.link}>
          &lt;&lt; Back to Character
        </Link>
      </div>
      
      <h1 style={{...styles.formTitle, textAlign: 'center', marginBottom: '30px'}}>
        Image Details for {character.name}
      </h1>

      <div style={styles.formGroup}>
        <div style={styles.editButtonContainer}>
          {canEdit && (
            <button
              onClick={() => setIsEditingDescription(!isEditingDescription)}
              style={{...styles.button, ...styles.editButton}}
            >
              <Pencil size={16} />
            </button>
          )}
          <label style={styles.label}>Image Description:</label>
        </div>
        <div style={{ position: 'relative', width: '100%' }}>
          {isEditingDescription && canEdit ? (
            <>
              <textarea
                value={imageDescription}
                onChange={handleDescriptionChange}
                style={{...styles.textarea, textAlign: 'left'}}
                placeholder="Enter image description"
              />
              <div style={{
                display: 'flex',
                gap: '10px',
                marginTop: '10px'
              }}>
                <button onClick={handleDescriptionSave} style={styles.button}>
                  Save
                </button>
                <button 
                  onClick={() => setIsEditingDescription(false)} 
                  style={{...styles.button, backgroundColor: '#6c757d'}}
                >
                  Cancel
                </button>
              </div>
            </>
          ) : (
            <p style={{...styles.projectDescription, textAlign: 'left'}}>
              {imageDescription || 'No image description available.'}
            </p>
          )}
        </div>
      </div>

      {canEdit && imageDescription && (
        <div style={{...styles.formGroup, marginTop: '20px'}}>
          <button
            onClick={handleGenerateImageDetails}
            style={{
              ...styles.button,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '8px',
              backgroundColor: '#007BFF',
              opacity: isGenerating ? 0.7 : 1,
              width: '200px',
              margin: '0 auto'
            }}
            disabled={isGenerating}
          >
            <ImageIcon size={20} />
            <span>{isGenerating ? 'Generating...' : 'Create Image Details'}</span>
          </button>
        </div>
      )}

      {imageDetails && canEdit && (
        <>
          <CharacterImageDetails 
            details={imageDetails} 
            characterId={characterId}
            userId={user.user_id}
          />
          
          <div style={{...styles.formGroup, marginTop: '20px'}}>
            <button
              onClick={handleGenerateImage}
              style={{
                ...styles.button,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '8px',
                backgroundColor: '#4CAF50',
                opacity: isGeneratingImage ? 0.7 : 1,
                width: '200px',
                margin: '0 auto'
              }}
              disabled={isGeneratingImage}
            >
              <ImageIcon size={20} />
              <span>{isGeneratingImage ? 'Generating...' : 'Create Image'}</span>
            </button>
          </div>
          
          {latestImageUrl && (
            <div style={{
              marginTop: '30px',
              display: 'flex',
              justifyContent: 'center',
              width: '100%'
            }}>
              <img
                src={latestImageUrl}
                alt={`Generated image of ${character.name}`}
                style={{
                  maxWidth: '512px',
                  width: '100%',
                  height: 'auto',
                  borderRadius: '8px',
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'
                }}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default CharacterImageScreen;